import React, { useState,useEffect } from 'react';
import './talktoyourdata.css';
import { getTalkToYourDataResponse } from '../../Redux/Actions/action.talktoyourdata';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Markdown from 'react-markdown'

function TalkToYourData({ getTalkToYourDataResponse }) {
    const [question, setQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    const updateChatHistory = (newQuestion) => {
        if (newQuestion !== ''){
            let chats = [
                ...chatHistory,
                { sent: newQuestion }, 
                { loading: true }
            ]
            setChatHistory(chats);
            setQuestion('');
            getTalkToYourDataResponse(newQuestion).then(res => {
                chats = [...chats.filter(m=>Object.keys(m)!='loading'),{ received: res.data.output }];
                setChatHistory(chats);
            }).catch(error => { console.log(error) });
        }
    }

    const handleKeyPress = (event) => {
        if (event.key == 'Enter') {
            if (question != '') {
                updateChatHistory(question);
                return
            }
            setQuestion('');
        }
    }
    const handleChangeValue = (value) => {
        if(value.length <500) setQuestion(value);
    }
    return (
        <div className='w-100 px-4 d-flex flex-column'>
            <div className='document-title py-2'>
                <div className='document-heading'>Talk To Your Data</div>
            </div>
        <div className='w-100 bg-white chat-container d-flex flex-column'>
            <div className="message-container grow-1">
                {chatHistory && chatHistory.length > 0 && chatHistory.map((message, index) => {
                    return <>
                        {Object.keys(message)=='sent' && 
                            <div className="message receiver-message text-end px-3 py-2 my-3" key={index}>
                                {message.sent}
                            </div>}
                        {Object.keys(message)=='received' && 
                            <div className='d-flex'>
                                <div className='avtar-bloc d-flex align-items-center justify-content-center p-2 mx-2 me-2 rounded-circle shadow-sm'><img src="images/BT-Logo-Color-Graphic.png" alt="Sender Avatar" className="avatar" /> </div>
                                <div className="message sender-message text-start" key={index}>                                    
                                    <Markdown>{message.received}</Markdown>
                                </div>
                            </div>}
                        {Object.keys(message)=='loading' &&
                        <div className='d-flex'>
                        <div className='avtar-bloc d-flex align-items-center justify-content-center p-2 mx-2 me-2 rounded-circle shadow-sm'><img src="images/BT-Logo-Color-Graphic.png" alt="Sender Avatar" className="avatar" /> </div>
                        <div className="message sender-message mx-2 p-3 loading" key={index}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        </div>}
                    </>
                })}
            </div>
            <div className='message-section bg-white mb-3 mx-4'>
                <div className="message d-flex">
                    <textarea type="text" aria-invalid="false" placeholder="My question is..." value={question} onChange={(e) => handleChangeValue(e.target.value)} onKeyUp={(e) => { handleKeyPress(e) }} />
                    <button onClick={() => updateChatHistory(question)}><img src='images/send.svg' /></button>
                </div>
                <div className='message-count'>{question.length}/500</div>
            </div>
        </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getTalkToYourDataResponse
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TalkToYourData);